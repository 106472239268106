<script lang='ts'>
    import { onDestroy } from 'svelte';
    import FontAwesome from './FontAwesome.svelte';
    import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

    export let user = {};
    console.log("Nav user", user);


    // the media queries that hides the menus are hardcoded so changing the content may cause the menu to wrap
    export let leftMenus = [
        {
            label: "Apps",
            items: [ 
                { label: "SimEditor", href: "/simeditor/editor" }, 
                { label: "ColaRIS", href: "/colaris/nnd" }, 
                { label: "RiskBIM", href: "/riskbim/cowi" }, 
                { label: "CRDoc Editor", href: "/crdoc/editor" }, 
                { label: "CRDoc Viewer", href: "/crdoc/viewer" },
            ]
        },
        {
            label: "Data",
            items: [ 
                { label: "Files", href: "/fs"}, 
                { label: "Point Clouds", href: "/pointcloud/pointcloud"}, 
                { label: "Point Cloud to Ifc", href: "/pointcloud/ifcconvert"}, 
                { label: "IFC", href: "/ifc" }, 
                { label: "S3", href: "/s3" }, 
                { label: "MappingDB", href: "/mappingdb" },
            ]
        },
        {
            label: "Demos",
            items: [ 
                { label: "Batching", href: "/experiments/batchdemo?modelUrl=https://localhost/fs/api/2c921e26-e632-4e4a-927d-3c953476b502/getFile/Models/halden_osm_16k.glb"}, 
                { label: "Closest Point Viewer", href: "/experiments/closestpointviewer/a/b"}, 
            ]
        },
    ];
    // if (user.admin) {
    //     leftMenus.push({
    //         label: "Admin",
    //         items: [ 
    //             { label: "Users", href: "/auth/admin/users"}, 
    //         ]
    //     });
    // }


    export let rightMenus = [
        {
            label: "About",
            items: [ 
                { label: "IFE", href: "https://ife.no" }, 
                { label: "HVRC", href: "https://ife.no/laboratorie/halden-virtual-reality-centre/" }, 
            ]
        },
    ];

    let selected = null;
    let selectedElem = null;

    let enter = (event, item) => {
        if (selected != null && selected != item) {
            selected = item;
            selectedElem = event.target;
        }
    }

    function selectSubmenu(e, name) {
        selected = name;
        selectedElem = e.target;
    }

    function closeSubmenu(e) {
        if (selectedElem && !selectedElem.contains(e.target)) {
            selected = null;
            selectedElem = null;
        }
    }
    
    document.addEventListener("click", closeSubmenu, false);    
    onDestroy(() => document.removeEventListener("click", closeSubmenu));
</script>

<nav>
    <div style="display: flex; align-items: center; flex: 1; height: 100%">
        <h1 class="logo"><a href="/">HVRC</a></h1>
        <ul class="menu pri2">            
            {#each leftMenus as menu}
                <li class="item has-submenu" 
                        on:click|stopPropagation={(e) => selectSubmenu(e, menu)}
                        on:mouseenter|preventDefault|stopPropagation={(event) => enter(event, menu)}>
                    <a href={'#'}>
                        <div>{menu.label}</div>
                        <FontAwesome iconDefinition={faChevronDown} />
                    </a>
                    
                    <ul class="submenu {selected == menu ? "visible" : "hidden"}">
                        {#each menu.items as item}
                            <li class="subitem"><a href="{item.href}">{item.label}</a></li>
                        {/each}
                    </ul>
                </li>
            {/each}
        </ul>
    </div>
    <ul class="menu">
        {#each rightMenus as menu}
            <li class="item has-submenu pri" 
                    on:click|stopPropagation={(e) => selectSubmenu(e, menu)}
                    on:mouseenter|preventDefault|stopPropagation={(event) => enter(event, menu)}>
                <a href={'#'}>
                    <div>{menu.label}</div>
                    <FontAwesome iconDefinition={faChevronDown} />
                </a>
                    <ul class="submenu {selected == menu ? "visible" : "hidden"}">
                    {#each menu.items as item}
                        <li class="subitem"><a href="{item.href}">{item.label}</a></li>
                    {/each}
                </ul>
            </li>
        {/each}
        
        <li class="item has-submenu" 
                on:click|stopPropagation={(e) => selectSubmenu(e, "user")}
                on:mouseenter|preventDefault|stopPropagation={(event) => enter(event, "user")}>
            <a href={'#'}>
                <div>{user.name ? user.name : "Not logged in"}</div>
                <FontAwesome iconDefinition={faChevronDown} />
            </a>
            <ul class="submenu {selected == "user" ? "visible" : "hidden"}">
                {#if user.name == null} 
                    <li class="subitem"><a href="/account/login?hvrc_return_to={window.location.href}">Log in</a></li>
                {:else} 
                    {#if user.admin} 
                        <li class="subitem"><a href="/account/admin">Admin</a></li>
                    {/if}
                    <li class="subitem"><a href="/account/profile">Profile</a></li>
                    <li class="divider"></li>
                    <li class="subitem"><a href="/account/logout?redirect_url={window.location.href}">Log out</a></li>
                {/if}
            </ul>
        </li>
    </ul>
</nav>


<style>
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
nav {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5em;    
    background-image: linear-gradient(#04519b, #044687 60%, #033769); 
    border-radius: 0.25em;
    border-color: #022f5a; 
    border: 1px solid transparent;
    padding: 0 15px;
    margin: 15px 0;
    min-height2: 5ex;
}
a {
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
}
.divider {
    height: 1px;
    margin: 9px 0;    
    overflow: hidden;
    color: #555555;
    background-color: #e5e5e5;
}
.visible {
    display: flex;
}
.hidden {
    display: none;
}
.logo a {
    font-size: 20px;
    margin: 0.25em 1em 0.25em 0;
    color: white;
}
.menu {
    display: flex;
    flex-wrap: wrap; 
    list-style-type: none;
}
.item {
    cursor: pointer;
    color: white;
}
.item a {
    color: white;
    display: block;
    padding: 0.75em 1em;
}

.item:hover {
    background-color: #022f5a;
}

.has-submenu > a {
    display: flex;
    align-items: center;
    gap: 0.25em;
}

.submenu {
    flex-direction: column; 
    position: absolute;
    list-style-type: none;
    background: white;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 0 0 4px 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    z-index: 1000;
}
.subitem a {
    color: #333333;
    padding: 0.25em 1.25em;
 }
.subitem:hover {
    background-color: #2fa4e7;
}
.subitem:hover a {
    color: white;
}

@media all and (max-width: 550px) {
    .pri2 {
        display: none !important;
    }
}

@media all and (max-width: 700px) {
    .pri {
        display: none !important;
    }
}

</style>